/* workaround for https://github.com/bootstrap-vue/bootstrap-vue/issues/1560 */
/* source: _input-group.scss */

.input-group > .input-group-prepend > .b-dropdown > .btn,
.input-group > .input-group-append:not(:last-child) > .b-dropdown > .btn,
.input-group > .input-group-append:last-child > .b-dropdown:not(:last-child):not(.dropdown-toggle) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .b-dropdown > .btn,
.input-group > .input-group-prepend:not(:first-child) > .b-dropdown > .btn,
.input-group > .input-group-prepend:first-child > .b-dropdown:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
